<template>
  <div class="columns is-mobile">
    <div class="column has-background-primary">
      <small class="has-text-white is-uppercase">
        Records {{ total > 0 ? firstItem : 0 }} to
        {{ Math.min(page * perPage, total) }} of {{ total }}
        records
      </small>
    </div>

    <div class="column is-narrow has-background-info">
      <slot name="page-dropdown" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableFooter',

  props: {
    firstItem: {
      type: Number,
      required: true,
    },

    page: {
      type: Number,
      required: true,
    },

    total: {
      type: Number,
      required: true,
    },

    perPage: {
      type: Number,
      required: true,
    },
  },

};
</script>

<style>

</style>
