<template>
  <!--TODO: Refactor UI form to match the one in components/Contacts/ContactsFormModal -->
  <div class="card is-primary">
    <div class="card-content">
      <div class="content">
        <p>Do you want to save?</p>
      </div>
      <b-field>
        <div class="buttons">
          <b-button
            id="inlineSavePrompt"
            type="is-success"
            value="Yes"
            @click="handleSave"
          >
            yes
          </b-button>

          <b-button
            id="inlineCancelPrompt"
            type="is-info"
            @click="$emit('close')"
          >
            no
          </b-button>
        </div>
      </b-field>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InlineEditingSavePrompt',

  data: () => ({
    isLoading: false,
  }),

  methods: {
    handleSave() {
      this.$emit('save');
      this.$emit('close');
    },
  },

};
</script>
<style lang="css" scoped>
</style>
