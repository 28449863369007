<template>
  <div class="columns">
    <div class="column is-one-quarter">
      <div
        class="box has-background-grey has-background-image is-pointer"
        @click="redirectToAccounts"
      >
        <div class="content has-text-centered">
          <h3 class="title has-text-white">
            {{ activeAccountsCount }}
          </h3>
          <h4 class="subtitle has-text-white">
            Active Accounts
          </h4>
        </div>
      </div>
    </div>

    <div class="column">
      <div
        class="box has-background-info has-background-image is-pointer"
        @click="redirectToContacts"
      >
        <div class="content has-text-centered">
          <h3 class="title has-text-white">
            {{ contactsCount }}
          </h3>
          <h4 class="subtitle has-text-white">
            Contacts
          </h4>
        </div>
      </div>
    </div>

    <div class="column">
      <div
        class="box has-background-primary has-background-image is-pointer"
        @click="redirectToActivities"
      >
        <div class="content has-text-centered">
          <h3 class="title has-text-white">
            {{ openActivitiesCount }}
          </h3>
          <h4 class="subtitle has-text-white">
            Open Activities
          </h4>
        </div>
      </div>
    </div>

    <div class="column">
      <div
        class="box has-background-success has-background-image is-pointer"
        @click="redirectToLeads"
      >
        <div class="content has-text-centered">
          <h3 class="title has-text-white">
            {{ leadsCount }}
          </h3>
          <h4 class="subtitle has-text-white">
            Leads
          </h4>
        </div>
      </div>
    </div>

    <div class="column">
      <div
        class="box has-background-warning has-background-image is-pointer"
        @click="redirectToOpportunities"
      >
        <div class="content has-text-centered">
          <h3 class="title has-text-white">
            {{ opportunitiesCount }}
          </h3>
          <h4 class="subtitle has-text-white">
            Opportunities
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Drilldowns',

  props: {
    activeAccountsCount: {
      type: Number,
      required: true,
    },

    contactsCount: {
      type: Number,
      required: true,
    },

    leadsCount: {
      type: Number,
      required: true,
    },

    opportunitiesCount: {
      type: Number,
      required: true,
    },

    openActivitiesCount: {
      type: Number,
      required: true,
    },
  },

  methods: {
    redirectToAccounts() {
      if (this.$route.name === 'Profile') {
        this.$router.push('/accounts');
      } else {
        this.$router.push('/accounts');
      }
    },

    redirectToContacts() {
      if (this.$route.name === 'Profile') {
        this.$router.push('/contacts');
      } else {
        this.$router.push('/contacts');
      }
    },

    redirectToActivities() {
      if (this.$route.name === 'Profile') {
        this.$router.push(`/activities?isComplete=${false}&activityTypeNotLike=Note`);
      } else {
        this.$router.push(`/activities?isComplete=${false}&activityTypeNotLike=Note`);
      }
    },

    redirectToLeads() {
      if (this.$route.name === 'Profile') {
        this.$router.push('/leads?status=open');
      } else {
        this.$router.push('/leads?status=open');
      }
    },

    redirectToOpportunities() {
      if (this.$route.name === 'Profile') {
        this.$router.push(`/opportunities?openStage=${true}`);
      } else {
        this.$router.push(`/opportunities?openStage=${true}`);
      }
    },
  },
};
</script>

<style scoped>
.has-background-image {
  border-radius: 10px;
  background-image: url(../../assets/images/mask.png);
  background-position: center;
  background-size: cover;
}
</style>
