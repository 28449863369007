<template>
  <!--TODO: Refactor UI form to match the one in components/Contacts/ContactsFormModal -->
  <div class="card is-primary">
    <header class="card-header">
      <p class="card-header-title is-uppercase">
        <b-icon
          icon="pencil"
          type="is-white"
        />
        <span class="is-padded-left">Reason for Win/Loss</span>
      </p>
      <b-button
        icon-right="close"
        type="is-text"
        class="has-text-info"
        @click="cancelEntry"
      />
    </header>

    <div class="card-content">
      <!-- loading component -->
      <b-loading
        v-model="isLoading"
        :is-full-page="false"
        :can-cancel="false"
      />
      <!-- account form modal component -->
      <validation-observer
        ref="closingReason"
        v-slot="{ invalid, handleSubmit }"
      >
        <form @submit.prevent="handleSubmit()">
          <div class="columns">
            <div class="column is-half">
              <b-field
                label="Opportunity Name"
                class="has-text-white"
              >
                <b-input
                  v-model="name"
                  disabled
                />
              </b-field>
            </div>
            <div class="column is-half">
              <b-field
                label="Opportunity Stage"
              >
                <b-select
                  v-model="stageId"
                  :disabled="$route.name !== 'ViewOpportunity'"
                  expanded
                >
                  <option
                    v-for="(stage, index) in opportunityStages"
                    :key="index"
                    :value="stage.orderSequence"
                  >
                    {{ stage.value }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div
            v-if="hasReasons"
            class="columns"
          >
            <div class="column">
              <validation-provider
                v-slot="{ errors }"
                ref="closingReasonField"
                :rules="{'required': required}"
                name="closing reason"
              >
                <b-field
                  label="Closing reason"
                  expanded
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                  :class="{'is-required': required}"
                >
                  <b-select
                    v-model="closingReason"
                    expanded
                  >
                    <option
                      v-for="(reason, index) in opportunityClosingReasons"
                      :key="index"
                      :value="reason.id"
                    >
                      {{ reason.reasonValue }}
                    </option>
                  </b-select>
                </b-field>
              </validation-provider>
            </div>
          </div>

          <b-field>
            <div class="buttons">
              <b-button
                v-if="$route.name === 'ViewOpportunity'"
                type="is-success"
                :disabled="invalid"
                @click="handleSave"
              >
                Update Opportunity
              </b-button>
              <b-button
                v-else
                type="is-success"
                :disabled="invalid"
                @click="handleSave"
              >
                Save Reason
              </b-button>

              <b-button
                type="is-info"
                @click="cancelEntry"
              >
                cancel
              </b-button>
            </div>
          </b-field>
        </form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

export default {
  name: 'OpportunityClosingReasonModal',
  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      opportunityStages: 'Lookups/getOpportunityStages',
      opportunityReasonStages: 'Opportunities/getOpportunityReasonStages',
      opportunityClosingReasons: 'Opportunities/getOpportunityStageReasons',

    }),

    name: {
      get() {
        return this.$store.state.Opportunities.opportunity.name;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_NAME', value);
      },
    },
    stageId: {
      get() {
        return this.$store.state.Opportunities.opportunity.stageId;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_STAGE_ID', value);
      },
    },
    closingReason: {
      get() {
        return this.$store.state.Opportunities.opportunity.reasonId;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_CLOSING_REASON', value);
      },
    },

    required() {
      if (!this.opportunityStages || !this.stageId) return false;

      const matchingStages = this.opportunityStages.filter((i) => i.orderSequence === this.stageId);
      if (matchingStages.length === 0) return false;

      const { code } = matchingStages[0];

      if (code && code.includes('closed') && this.opportunityReasonStages.length > 0) {
        const result = this.opportunityReasonStages.filter((i) => i.stageCode === code);
        return result.length > 0 ? result[0].isRequired : false;
      }
      return false;
    },

    hasReasons() {
      if (!this.opportunityStages || !this.stageId) return false;

      const matchingStages = this.opportunityStages.filter((i) => i.orderSequence === this.stageId);
      if (matchingStages.length === 0) return false;

      const { code } = matchingStages[0];

      if (code && code.includes('closed') && this.opportunityReasonStages.length > 0) {
        const result = this.opportunityReasonStages.filter((i) => i.stageCode === code);
        return result.length > 0;
      }
      return false;
    },
  },

  async created() {
    await this.$store.dispatch('Opportunities/fetchOpportunityReasonStages');
  },

  beforeDestroy() {
    this.$store.commit('Opportunities/SET_OPPORTUNITY_CLOSING_REASON', null);
  },

  methods: {
    // async createContact() {
    //   this.isLoading = true;
    //   const payload = { ...this.contact };
    //   try {
    //     const response = await this.$store.dispatch('Contacts/createContact', payload);
    //     if (this.moduleName === 'leads') {
    //       this.$store.commit('Leads/SET_LEAD_PRIMARY_CONTACT_ID', response.id);
    //     } else {
    //     // await this.$store.dispatch('Contacts/fetchContacts');
    //     this.$emit('close');
    //   } catch (error) {
    //     console.error(error);
    //   } finally {
    //     this.isLoading = false;
    //   }
    // },

    cancelEntry() {
      this.$store.commit('Opportunities/SET_OPPORTUNITY_CLOSING_REASON', null);
      this.$emit('close');
    },

    handleSave() {
      this.$emit('save');
      this.$emit('close');
    },

    resetValidator() {
      this.$refs.closingReasonField.reset();
    },
  },

};
</script>
<style lang="css" scoped>
</style>
