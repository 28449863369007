<template>
  <!--TODO: Refactor UI form to match the one in components/Contacts/ContactsFormModal -->
  <div class="card is-primary">
    <header class="card-header">
      <p class="card-header-title is-uppercase">
        <b-icon
          icon="pencil"
          type="is-white"
        />
        <span class="is-padded-left">Reason for Win/Loss</span>
      </p>
      <b-button
        icon-right="close"
        type="is-text"
        class="has-text-info"
        @click="cancelEntry"
      />
    </header>

    <div class="card-content">
      <!-- loading component -->
      <b-loading
        v-model="isLoading"
        :is-full-page="false"
        :can-cancel="false"
      />
      <!-- account form modal component -->
      <validation-observer
        ref="closingReason"
        v-slot="{ invalid, handleSubmit }"
      >
        <form @submit.prevent="handleSubmit()">
          <div class="columns">
            <div class="column is-half">
              <b-field
                label="Lead Name"
                class="has-text-white"
              >
                <b-input
                  v-model="leadName"
                  disabled
                />
              </b-field>
            </div>
            <div class="column is-half">
              <b-field
                label="Lead Stage"
              >
                <b-select
                  v-model="stageId"
                  disabled
                  expanded
                >
                  <option
                    v-for="(stage, index) in leadStages"
                    :key="index"
                    :value="stage.id"
                  >
                    {{ stage.value }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div
            v-if="hasReasons"
            class="columns"
          >
            <div class="column">
              <validation-provider
                v-slot="{ errors }"
                ref="closingReasonField"
                :rules="{'required': required}"
                name="closing reason"
              >
                <b-field
                  label="Closing reason"
                  expanded
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                  :class="{'is-required': required}"
                >
                  <b-select
                    v-model="closingReason"
                    expanded
                  >
                    <option
                      v-for="(reason, index) in opportunityClosingReasons"
                      :key="index"
                      :value="reason.id"
                    >
                      {{ reason.reasonValue }}
                    </option>
                  </b-select>
                </b-field>
              </validation-provider>
            </div>
          </div>

          <b-field>
            <div class="buttons">
              <b-button
                v-if="$route.name === 'ViewOpportunity'"
                type="is-success"
                :disabled="invalid"
                @click="handleSave"
              >
                Update Opportunity
              </b-button>
              <b-button
                v-else
                type="is-success"
                :disabled="invalid"
                @click="handleSave"
              >
                Save Reason
              </b-button>

              <b-button
                type="is-info"
                @click="cancelEntry"
              >
                cancel
              </b-button>
            </div>
          </b-field>
        </form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

export default {
  name: 'LeadClosingReasonModal',
  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      leadStages: 'Lookups/getLeadStatuses',
      opportunityReasonStages: 'Opportunities/getOpportunityReasonStages',
      opportunityClosingReasons: 'Opportunities/getOpportunityStageReasons',

    }),

    leadName: {
      get() {
        return this.$store.state.Leads.lead.name;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_NAME', value);
      },
    },
    stageId: {
      get() {
        return this.$store.state.Leads.lead.stageId;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_STAGE_ID', value);
      },
    },
    closingReason: {
      get() {
        return this.$store.state.Leads.lead.reasonId;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_CLOSING_REASON', value);
      },
    },

    required() {
      if ((this.stageId === 143 || this.stageId === 144
      || this.stageId === 145 || this.stageId === 146)
      && this.leadStages.length > 0) {
        const { code } = this.leadStages.filter((i) => i.id === this.stageId)[0];
        let stage;
        if (code === 'closed_converted') {
          stage = 'closed_won';
        } else if (code === 'closed_not_converted') {
          stage = 'closed_lost';
        } else {
          stage = code;
        }
        const result = this.leadStages.filter((i) => i.stageCode === stage);
        return result.length > 0 ? result[0].isRequired : false;
      }
      return false;
    },

    hasReasons() {
      if ((this.stageId === 145 || this.stageId === 146)
      && this.leadStages.length > 0) {
        const { code } = this.leadStages.filter((i) => i.id === this.stageId)[0];
        let stage;
        if (code === 'closed_converted') {
          stage = 'closed_won';
        } else if (code === 'closed_not_converted') {
          stage = 'closed_lost';
        } else {
          stage = code;
        }
        const result = this.opportunityReasonStages.filter((i) => i.stageCode === stage);
        return result.length > 0;
      }
      return false;
    },
  },

  async created() {
    await this.$store.dispatch('Opportunities/fetchOpportunityReasonStages');
  },

  beforeDestroy() {
    this.$store.commit('Leads/SET_LEAD_CLOSING_REASON', null);
  },

  methods: {
    cancelEntry() {
      this.$store.commit('Opportunities/SET_OPPORTUNITY_CLOSING_REASON', null);
      this.$emit('close');
    },

    handleSave() {
      this.$emit('save');
      this.$emit('close');
    },

    resetValidator() {
      this.$refs.closingReasonField.reset();
    },
  },

};
</script>
<style lang="css" scoped>
</style>
