<template>
  <div class="level dashboard-navbar">
    <div class="level-left">
      <div
        v-if="$route.name.startsWith('View')"
        class="level-item"
      >
        <slot name="title" />
      </div>
      <div
        v-else
        class="level-item"
      >
        <div class="content">
          <h1 class="title has-text-primary is-capitalized">
            <b-icon :icon="icon" />
            <slot name="link" /> {{ name }}
          </h1>
        </div>
      </div>
    </div>

    <div class="level-right">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',

  props: {
    name: {
      type: String,
      default: '',
    },

    icon: {
      type: String,
      default: '',
    },
  },

};
</script>

<style>
</style>
