<template>
  <div>
    <div class="level">
      <div class="level-item has-text-centered">
        <div>
          <figure class="image">
            <img
              src="@/assets/images/no-data.svg"
              alt="No Data"
            >
          </figure>
          <b-button
            type="is-primary"
            size="is-small"
          >
            no {{ tableName }} data found
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyTable',

  props: {
    tableName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>

</style>
