<template>
  <div>
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="false"
    />

    <div class="level">
      <div class="level-left">
        <b-field
          v-if="false"
          expanded
        >
          <b-input
            size="is-small"
            placeholder="Search..."
            icon="search"
            @input="$emit('search', $event)"
          />
        </b-field>
      </div>

      <div class="level-item">
        <div>
          <slot name="update-button" />
        </div>
      </div>

      <div class="level-right">
        <div>
          <slot name="re-assign-button" />
        </div>

        <div>
          <slot name="open-switch-button" />
        </div>

        <div>
          <slot name="reset-button" />
        </div>

        <div>
          <slot name="hide-show-columns" />
        </div>

        <div>
          <slot name="customize" />
        </div>

        <div
          v-if="
            (hasRole('ROLE_BUSINESS_ADMIN') || hasRole('ROLE_SALES_MANAGER'
              || allowSalesPeopleExport))
              && $route.name !== 'Organization'"
        >
          <b-dropdown
            aria-role="list"
            postition="is-bottom-left"
          >
            <b-button
              slot="trigger"
              size="is-small"
              icon-left="download"
              icon-right="chevron-down"
            >
              Export
            </b-button>

            <b-dropdown-item aria-role="listitem">
              <small @click="$emit('export-xls')">Export as XLSX</small>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div v-show="false">
          <download-excel
            ref="downloadExcel"
            :fields="fields"
            :name="`${name}.xls`"
          />

          <download-excel
            ref="drilldownDownloadExcel"
            :fields="fields"
            :name="`${name}.xls`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TableHeader',

  props: {
    fields: {
      type: Object,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    downloadData: [],
    downloadStatus: false,
  }),

  computed: {
    ...mapGetters({
      isLoading: 'Exports/getLoading',
      accounts: 'Exports/getAccounts',
      contacts: 'Exports/getContacts',
      leads: 'Exports/getLeads',
      opportunities: 'Exports/getOpportunities',
      activities: 'Exports/getActivities',
      documents: 'Exports/getDocuments',
      usersGoals: 'Exports/getUsersGoals',
      allowSalesPeopleExport: 'Settings/getAllowSalesPeopleExport',
    }),
  },

  watch: {
    isLoading: {
      handler(value) {
        if (this.name === 'accounts') {
          this.downloadData = this.accounts;
        } if (this.name === 'contacts') {
          this.downloadData = this.contacts;
        } if (this.name === 'leads' || this.name === 'leads-drilldown') {
          this.downloadData = this.leads;
        } if (this.name === 'opportunities' || this.name === 'opportunities-drilldown') {
          this.downloadData = this.opportunities;
        } if (this.name === 'activities') {
          this.downloadData = this.activities;
        } if (this.name === 'documents') {
          this.downloadData = this.documents;
        } if (this.name === 'usersGoals') {
          this.downloadData = this.usersGoals;
        }
        if (!value) {
          this.downloadStatus = true;
        }
      },
      deep: true,
    },

    downloadStatus: {
      handler(value) {
        if (value) {
          this.$refs.downloadExcel.fields = this.fields;
          this.$refs.downloadExcel.data = this.downloadData;
          this.$refs.downloadExcel.generate();
          this.downloadStatus = false;
        }
      },
      deep: true,
    },
  },

  async created() {
    await this.$store.dispatch('Settings/fetchCustomizations');
  },
};
</script>

<style>

</style>
