var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns is-bordered"},[_c('router-link',{staticClass:"column is-one-quarter is-pointer content",attrs:{"tag":"div","to":{ name: 'ViewAccount', params: { accountId: _vm.account.id } }}},[_c('h4',{staticClass:"subtitle has-text-primary"},[_vm._v(" "+_vm._s(_vm.account.name)+" ")]),_c('p',[(_vm.account.owners && _vm.account.owners.length > 0)?[_vm._l((_vm.account.owners),function(owner,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(owner.owner ? `${owner.owner.firstName} ${owner.owner.lastName}` : '')+" "),(index < 1 && _vm.account.owners.length > 1 && _vm.account.owners[index].owner)?[_c('br')]:_vm._e()],2)}),_c('br')]:_vm._e(),(_vm.account.industries)?_c('b-tag',[_vm._v(" "+_vm._s(_vm.account.industries[0].value)+" ")]):_vm._e(),_c('br'),_c('span',[_vm._v(_vm._s(_vm._f("date")(_vm.account.createdAt)))])],2)]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('router-link',{staticClass:"box has-background-primary",attrs:{"to":{
            name: 'ViewAccount',
            params: { accountId: _vm.account.id },
            query: { activeTab: 3 },
          }}},[_c('div',{staticClass:"content has-text-centered"},[_c('b-icon',{attrs:{"icon":"bullseye","type":"is-white"}}),_c('p',{staticClass:"has-text-white"},[_c('span',{staticClass:"is-capitalized"},[_vm._v("opportunities")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"is-size-4"},[_vm._v(_vm._s(_vm.account.opportunitiesCount))])])],1)])],1),_c('div',{staticClass:"column"},[_c('router-link',{staticClass:"box has-background-info",attrs:{"to":{
            name: 'ViewAccount',
            params: { accountId: _vm.account.id },
            query: { activeTab: 2 },
          }}},[_c('div',{staticClass:"content has-text-centered"},[_c('b-icon',{attrs:{"icon":"star","type":"is-white"}}),_c('p',{staticClass:"has-text-white"},[_c('span',{staticClass:"is-capitalized"},[_vm._v("leads")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"is-size-4"},[_vm._v(_vm._s(_vm.account.leadsCount))])])],1)])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }