<template>
  <div class="columns is-bordered">
    <router-link
      tag="div"
      :to="{ name: 'ViewAccount', params: { accountId: account.id } }"
      class="column is-one-quarter is-pointer content"
    >
      <h4 class="subtitle has-text-primary">
        {{ account.name }}
      </h4>
      <p>
        <template v-if="account.owners && account.owners.length > 0">
          <span
            v-for="(owner, index) in account.owners"
            :key="index"
          >
            {{ owner.owner ? `${owner.owner.firstName} ${owner.owner.lastName}` : '' }}
            <template v-if="index < 1 && account.owners.length > 1 && account.owners[index].owner">
              <br>
            </template>
          </span>
          <br>
        </template>

        <b-tag v-if="account.industries">
          {{ account.industries[0].value }}
        </b-tag><br>

        <span>{{ account.createdAt | date }}</span>
      </p>
    </router-link>

    <div class="column">
      <div class="columns">
        <div class="column">
          <router-link
            :to="{
              name: 'ViewAccount',
              params: { accountId: account.id },
              query: { activeTab: 3 },
            }"
            class="box has-background-primary"
          >
            <div class="content has-text-centered">
              <b-icon
                icon="bullseye"
                type="is-white"
              />
              <p class="has-text-white">
                <span class="is-capitalized">opportunities</span> <br>
                <span class="is-size-4">{{ account.opportunitiesCount }}</span>
              </p>
            </div>
          </router-link>
        </div>
        <div class="column">
          <router-link
            :to="{
              name: 'ViewAccount',
              params: { accountId: account.id },
              query: { activeTab: 2 },
            }"
            class="box has-background-info"
          >
            <div class="content has-text-centered">
              <b-icon
                icon="star"
                type="is-white"
              />

              <p class="has-text-white">
                <span class="is-capitalized">leads</span> <br>
                <span class="is-size-4">{{ account.leadsCount }}</span>
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileFavouriteAccountsCard',

  props: {
    account: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.is-bordered {
  width: 100%;
  border: 1px solid #cccccc;
  margin: 5px;
}
</style>
