<template>
  <div>
    <form
      method="post"
      novalidate="true"
      @submit.prevent="updateOwner"
    >
      <div
        class="modal-card"
        style="width: auto"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ modalTitle }}
          </p>
        </header>

        <section class="modal-card-body">
          <div class="modal-card-body__errors">
            <ul>
              <li
                v-for="(error, index) in errors"
                :key="index"
                class="text-italic"
              >
                {{ error }}
              </li>
            </ul>
          </div>

          <b-field
            v-show="routeName !== 'ListContacts'"
            label="Search Owner To Re-assign"
            label-for="owner"
          >
            <b-autocomplete
              v-model="owner"
              icon-right="search"
              expanded
              placeholder="Owner name"
              field="fullName"
              :loading="isLoading"
              :data="ownerSearchResults"
              @typing="getOwnersAsyncData"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.fullName.trim() }}
                    <br>
                    <small>
                      email: {{ props.option.email }} <br>
                      Phone: {{ props.option.phoneCell }}
                    </small>
                  </div>
                </div>
              </template>

              <template #empty>
                No results for {{ owner }}
              </template>
            </b-autocomplete>
          </b-field>

          <b-field
            label="New Owner"
            label-for="new owner"
          >
            <b-autocomplete
              v-model="newOwner"
              icon-right="search"
              expanded
              placeholder="Owner name"
              field="fullName"
              :loading="isLoading"
              :data="newOwnerSearchResults"
              @typing="getNewOwnersAsyncData"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.fullName.trim() }}
                    <br>
                    <small>
                      email: {{ props.option.email }} <br>
                      Phone: {{ props.option.phoneCell }}
                    </small>
                  </div>
                </div>
              </template>

              <template #empty>
                No results for {{ newOwner }}
              </template>
            </b-autocomplete>
          </b-field>
        </section>

        <footer class="modal-card-foot">
          <b-input
            type="submit"
            value="SAVE"
          />
          <b-button
            class="ml-4"
            label="Close"
            @click="closeModal"
          />
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';

export default {
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    dataIDList: {
      type: Array,
      default: () => [],
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    owner: null,
    newOwner: null,
    ownerSearchResults: [],
    newOwnerSearchResults: [],
    errors: [],
  }),

  methods: {
    async fetchOwnerId(username) {
      try {
        return await this.$store.dispatch('Owners/getOwnerID', {
          username,
        });
      } catch (err) {
        console.error('get owner', err);
      }

      return null;
    },

    getOwnersAsyncData: debounce(function (token) {
      this.isLoading = true;

      this.$store
        .dispatch('Search/searchUsers', token)
        .then((result) => {
          this.ownerSearchResults = result;
        })
        .catch((error) => {
          this.ownerSearchResults = [];
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 500),

    getNewOwnersAsyncData: debounce(function (token) {
      this.isLoading = true;

      this.$store
        .dispatch('Search/searchUsers', token)
        .then((result) => {
          this.newOwnerSearchResults = result;
        })
        .catch((error) => {
          this.newOwnerSearchResults = [];
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 500),

    async updateOwner() {
      this.errors = [];

      let ownerId = '';
      if (this.routeName !== 'ListContacts') {
        const oldSelectedOwnerResults = this.ownerSearchResults.filter(
          (user) => user.fullName === this.owner,
        );
        ownerId = oldSelectedOwnerResults[0].userId;
      }

      const newSelectedOwnerResults = this.newOwnerSearchResults.filter(
        (user) => user.fullName === this.newOwner,
      );
      const newOwnerId = newSelectedOwnerResults[0].userId;

      try {
        await this.$store.dispatch('Owners/updateOwner', {
          ownerId,
          newOwnerId,
          ids: [...this.dataIDList],
          routeName: this.routeName,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit('close');
      }
    },

    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  color: crimson;
}

.text-italic {
  font-style: italic;
}

.modal-card-body {
  padding: 2rem 1rem;
}

.save-btn {
  background-color: #1b3869;
  color: #ffffff;
}
</style>
