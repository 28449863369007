<template>
  <section>
    <!-- <template #trigger> -->
    <section class="section has-background-light">
      <div class="container">
        <div
          style="display: flex; align-items: center; justify-content: space-between;"
        >
          <slot name="breadcrumb" />
          <!-- @click="() => $emit('toggle-widget-drawer')" -->

          <div
            style="display: flex; align-items: center; cursor: pointer"
            role="button"
            aria-controls="contentIdForA11y2"
            @click="() => {isOpen ? isOpen=false : isOpen=true}"
          >
            <template v-if="isOpen">
              <strong>Collapse Widgets</strong>
              <b-button
                :key="isOpen"
                aria-label="collapse"
                type="is-text"
                icon-right="arrow-up"
                style="color: #000000;"
              />
            </template>
            <template v-else>
              <strong>Expand Widgets</strong>
              <b-button
                :key="isOpen"
                aria-label="expand"
                type="is-text"
                icon-right="arrow-down"
                style="color: #000000;"
              />
            </template>
          </div>
        </div>
      </div>
    </section>
    <!-- </template> -->
    <b-collapse
      v-model="isOpen"
      aria-id="contentIdForA11y2"
      class="panel"
      animation="slide"
    >
      <template>
        <slot />
      </template>
    </b-collapse>
  </section>
</template>

<script>
export default {
  name: 'WidgetCollapseDrawer',

  computed: {
    isOpen: {
      get() {
        return this.$store.state.Settings.isWidgetsOpen;
      },
      set(value) {
        this.$store.commit('Settings/SET_WIDGETS_OPEN', value);
      },
    },
  },
};
</script>

<style>
.panel {
  border-radius: 0;
  box-shadow: none;
}

</style>
