<template>
  <div class="buttons">
    <template v-if="isEditing">
      <b-tooltip
        type="is-info"
        label="Update"
        position="is-bottom"
      >
        <b-button
          type="is-text"
          class="has-text-grey"
          icon-right="save"
          @click="$emit('update')"
        />
      </b-tooltip>

      <b-tooltip
        type="is-info"
        label="Cancel"
        position="is-bottom"
      >
        <b-button
          type="is-text"
          class="has-text-grey"
          icon-right="close"
          @click="$emit('cancel')"
        />
      </b-tooltip>
    </template>

    <template v-else>
      <slot name="actions" />

      <b-tooltip
        type="is-info"
        label="Edit"
        position="is-bottom"
      >
        <b-button
          type="is-text"
          class="has-text-grey"
          icon-right="pencil"
          @click="$emit('edit')"
        />
      </b-tooltip>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TableActions',

  props: {
    isEditing: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style>

</style>
